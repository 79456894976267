export { LocaleCode, useAssetOnLocale, useSwichLocale, useConfigOnLocale }

enum LocaleCode {
  ZH = 'zh',
  EN = 'en',
}

function useAssetOnLocale(assets: Record<LocaleCode, Promise<{ default: string }>>) {
  const { locale } = useNuxtApp().$i18n
  const url = ref('')

  watch(
    locale as Ref<LocaleCode>,
    (locale) => {
      assets[locale]?.then((res) => {
        url.value = res.default
      })
    },
    { immediate: true },
  )

  return url
}

function useConfigOnLocale<C>(config: Record<LocaleCode, C>) {
  const { locale } = useI18n()

  return computed(() => (config as any)[locale.value])
}

function useSwichLocale() {
  const { locale, locales } = useI18n()
  const switchLocalePath = useSwitchLocalePath()
  const router = useRouter()
  const localeOptions = computed(() =>
    unref(locales).map((locale) => {
      return {
        value: typeof locale === 'string' ? locale : locale.code,
        label: typeof locale === 'string' ? locale : locale.name,
      }
    }),
  )
  const localeLabel = computed(() => {
    const _locale = unref(locale)
    const _localeOptions = unref(localeOptions)
    const targetLocale = _localeOptions.find(item => item.value === _locale)

    if (targetLocale)
      return targetLocale.label
    else return 'English'
  })

  watch(locale, (locale) => {
    router.replace(switchLocalePath(locale))
  })

  return { locale, localeLabel, localeOptions }
}
